<template>
	<div id="app">
		<div class="home">
			<header>
				<!-- 导航菜单 -->
				<div v-show="tabsShow" :class="scrollTop > 500 || show ? 'bg-c-fff' : ''" class="tabs">
					<div class="ls-flex-col h-100 ls-jc-center ls-al-center w-100">
						<div class="logo ls-flex-center">
							<router-link class="ls-flex-flex ls-flex-center" to="/">
								<img class="logo_img_1" src="./assets/logo_1.png" style="" alt="">
								<div class="logo_line m-lr-20"></div>
								<img class="logo_img_2" src="./assets/logo_2.png" style="" alt="">
							</router-link>
							<div class="phone_tabs_btn ls-flex-flex ls-flex">
								<div class="w-100 h-100 ls-flex ls-al-center ls-jc-space-between">
									<div></div>
									<img @click="popShow" src="../src/assets/phone_menu_2.png" style="width: 20px;height: 15px;" alt="">
								</div>
							</div>
						</div>
						<div class="tabs_content ls-flex p-b-30">
							<div class="ls-flex-flex tabs_btn window_tabs_btn">
								<ul class="w-100">
									<li class="hoverLi" @mousemove="tabsBtnMousemove(item)" v-for="(item, index) in lists" :key="index" :class="classes(item)">
										<router-link :style="{color: scrollTop > 500 ? '#000' : ''}" v-if="item.path != '/business' && item.path != '/'" :to="item.children ? item.children[0].path : item.path">{{ item.meta[$store.state.lang] }}</router-link>
										<router-link :style="{color: scrollTop > 500 ? '#000' : ''}" v-else :to="item.path">{{item.meta[$store.state.lang]}}</router-link>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div v-if="childrenList.length > 0" :style="{height: (childrenList.length / 2).toFixed() * 120 + 'px' }" class="tabs_box window_tabs_btn">
						<div class="ls-flex-center h-100">
							<div class="ls-flex tabs_box_box ls-jc-center">
								<div class="max-width ls-flex">
									<div class="ls-flex-col window_tabs_btn_left">
										<img :src="imgServe + indexData.qrcode" style="width: 103px;height: 103px;" alt="">
										<div class="ls-flex m-tb-20 ls-al-center">
											<img src="./assets/24gf-phoneLoudspeaker.png" style="width:15px;height: 14px;" alt="">
											<span class="fs-14 f-999 m-l-10">{{indexData.mobile || ''}}</span>
										</div>
										<div class="ls-flex ls-al-center">
											<img src="./assets/wz.png" style="width: 15px;height: 19px;" alt="">
											<span class="fs-14 f-999 m-l-10">{{indexData.address || ''}}</span>
										</div>
									</div>
									<div class="ls-flex-flex children ls-flex">
										<div class="children_item w-100">
											<div class="ls-flex ls-flex-rw w-100">
												<div v-if="childrenList && im.name != 'InformationDetail' && im.name != 'CultureDetail' && im.name != 'TakeOnDetail' && im.name != 'HuaZhangDetail' && im.name != 'ParkDetail' && im.name != 'RecruitDetail'" class="ls-flex-col children_item_im" v-for="(im,ix) in childrenList" :key="ix">
													<router-link @click.native="a" :to="im.path + '?type=' + im.meta[$store.state.lang]">
														<div class="ls-flex ls-al-center ls-jc-space-between m-b-15">
															<div class="ls-flex-col m-r-20 ls-flex-flex">
																<span class="fs-20 span_children_item_1 text-ellipsis-1">{{im.meta[$store.state.lang]}}</span>
																<span class="fs-20 span_children_item_2 text-ellipsis-1">{{im.meta['en']}}</span>
															</div>
															<div class="img"></div>
														</div>
														<div class="children_item_line">
															<div class="children_item_line_div h-100"></div>
														</div>
													</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</header>

			<main class="bg-c-f9f9f9">
				<router-view></router-view>
			</main>

			<footer>
				<!-- 相关 -->
				<div class="correlation">
				  <!-- 相关链接 -->
					<div class="corr-content">
						<ul class="ov-hidden m-r-40" v-for="(item, index) in routes" :key="index">
							<h2 @click="changeRoute(item)">{{ item.meta[$store.state.lang] }}</h2>
							<span></span>
							<template v-if="item.name == 'Service'">
							  <li v-for="(i,x) in serverList" :key="i.id">
								<router-link :to="`/Service/Main?id=${x}`" @click.native="toTop">{{ i.type_name }}</router-link>
							  </li>
							</template>
							<template v-else>
								<li v-if="item.children && i.name != 'InformationDetail' && i.name != 'CultureDetail' && i.name != 'TakeOnDetail' && i.name != 'HuaZhangDetail' && i.name != 'ParkDetail' && i.name != 'RecruitDetail'" v-for="(i, x) in item.children" :key="index + '-' + x">
									<template v-if="i.name != 'Detail' || i.name != 'NewsDetail'">
										<router-link :to="i.children ? i.children[0].path + '?type=' + x : i.path + '?type=' + i.meta[$store.state.lang]" @click.native="toTop">{{i.meta[$store.state.lang]}}</router-link>
									</template>
								</li>
							</template>
							
						</ul>
					</div>
					<div class="corr-content_phone">
						<ul class="ov-hidden ls-flex ls-flex-rw">
							<li v-for="(item, index) in routes" :key="index" @click="changeRoute(item)">{{ item.meta[$store.state.lang] }}</li>
						</ul>
					</div>
					<div class="ls-flex-col m-t-40 QRCode">
						<div class="m-b-30 QRCode_box">
							<div class="ls-flex ls-al-center m-b-30">
								<img src="./assets/foot_dianhua.png" style="width: 1.1875rem;height: 1.0625rem;" alt="">
								<span class="f-fff m-l-20 fs-20">{{indexData.mobile || ''}}</span>
							</div>
							<div class="ls-flex ls-al-center m-b-30">
								<img src="./assets/foot_wangzhi.png" style="width: 1.3125rem;height: 1.3125rem;" alt="">
								<span class="f-fff m-l-20 fs-20">{{indexData.website || ''}}</span>
							</div>
							<div class="ls-flex ls-al-center m-b-30">
								<img src="./assets/foot_youxiang.png" style="width: 1.4375rem;height: 1.1875rem;" alt="">
								<span class="f-fff m-l-20 fs-20">{{indexData.email || ''}}</span>
							</div>
							<div class="ls-flex ls-al-center">
								<img src="./assets/foot_dizhi.png" style="width: 1.375rem;height: 1.375rem;" alt="">
								<span class="f-fff m-l-20 fs-20">{{indexData.address || ''}}</span>
							</div>
						</div>
						<div class="ls-flex ls-jc-space-between ls-al-center QRCode_img">
							<div class="ls-flex-col w-max-content ls-al-center">
								<img :src="imgServe + indexData.qrcode" style="width: 6.25rem;height: 6.25rem;" alt="">
								<span class="f-fff m-t-20">{{$cetihuan.QRCode[$store.state.lang]}}</span>
							</div>
							<div class="parting-line-BFA180 m-lr-30"></div>
							<div class="ls-flex-col w-max-content ls-al-center">
								<!-- <img src="./assets/logo_2.png" style="width: 4.5rem;height: 3.125rem;" alt=""> -->
								<img src="./assets/logo_2.png" style="width: 9rem;height: 6.25rem;" alt="">
								<span class="f-fff m-t-20">三十华章</span>
							</div>
							
						</div>
					</div>
				</div>

				<div class="bottom">
					<p>
						Copyright©  2023-2024    {{$cetihuan.copyright[$store.state.lang]}}：{{indexData.name || ''}}    
					</p>
					<p class="m-l-10">
						{{$cetihuan.beian[$store.state.lang]}}:<a href="https://beian.miit.gov.cn/#/Integrated/index"target="_Blank">{{indexData.beian || ''}}</a>
						{{$cetihuan.jishu[$store.state.lang]}}：<a href="http://www.bjkhrs.com"target="_Blank">{{$cetihuan.khrs[$store.state.lang]}}</a>
					</p>
					<!-- <p>
						Copyright©  2022-2023{{$cetihuan.copyright[$store.state.lang]}}：{{indexData.company || ''}}    
						{{$cetihuan.beian[$store.state.lang]}}:<a href="https://beian.miit.gov.cn/#/Integrated/index"target="_Blank">{{indexData.beian || ''}}</a>
						{{$cetihuan.jishu[$store.state.lang]}}：<a href="http://www.bjkhrs.com"target="_Blank">科辉荣盛</a>
					</p> -->
				</div>
			</footer>
			<el-collapse-transition>
				<div class="home_popup" v-show="show">
					<div class="correlation">
					  <!-- 相关链接 -->
						<div class="corr-content">
							<!-- <div @click="changeLang" class="lang">中文/EN</div> -->
							<div class="ls-flex ls-flex-rw w-100">
								<div v-if="im.name != 'InformationDetail' && im.name != 'CultureDetail' && im.name != 'TakeOnDetail' && im.name != 'HuaZhangDetail' && im.name != 'ParkDetail' && im.name != 'RecruitDetail'" class="ls-flex-col children_item_im w-100" v-for="(im,ix) in lists" :key="ix">
									<router-link :to="im.path">
										<div class="ls-flex ls-al-center ls-jc-space-between m-b-15">
											<div class="ls-flex-col m-r-20 ls-flex-flex">
												<span class="fs-26 f-000 span_children_item_1 text-ellipsis-1">{{im.meta[$store.state.lang]}}</span>
												<span class="fs-24 f-999 span_children_item_2 text-ellipsis-1">{{im.meta['en']}}</span>
											</div>
											<div class="img"></div>
										</div>
										<div class="parting-line-f8f8f8 m-b-20"></div>
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</el-collapse-transition>
		</div>
		<div v-if="scrollTop > innerHeight" @click="toTop" class="top">
			<div class="w-100 h-100 ls-flex-col-center">
				<span class=" f-fff span_1"><</span>
				<span class=" f-fff">TOP</span>
			</div>
		</div>
	</div>
</template>

<script>
import routes from "./router/routes";
import { getServer, getIndexAd, getCreditList } from "@/api/api.js";
import store from '@/store'
export default {
	data() {
		return {
			lists: [],
			routes: [],
			name:'',
			show:true,
			branchCompanyList:[],
			branchCompanyListEn:[],
			cpList:[],
			cpListChina:[],
			cpListEn:[],
			indexData:{},
			childrenList:[],
			childrenObj:{},
			propsName:'HomePage',
			childrenName:'',
			imgServe:'',
			scrollTop:0,
			serverList:[],
			innerHeight:0,
			tabsShow:true
		};
	},

	created() {
		this.lists = routes.slice(0, 9);
		this.routes = routes.slice(0, 9);
		this.imgServe = this.$store.state.serverUrl;
		this.getData();
		
	},
	// 监听路由变化
	watch: {
		$route: {
			handler: function (val, oldVal) {
				this.name = val.name;
				this.show = false;
				this.toTop();
			},
			deep: true,
		},
		'$store.state.lang':{
			handler: function (val, oldVal) {
				//刷新页面
				this.lists = routes.slice(0, 8);
				this.routes = routes.slice(1, 7);
				this.getData();
			},
			deep: true,
		}
	},
	methods: {
		// 判断是否有子路由
		classes(el) {
			//判断子路由是否有匹配项
			if (el.name == this.name) {
				return el.name == this.name ? "active" : "";
			} else if (el.children) {
				return el.children.some((item) => {
					if (item.children) {
						return item.children.some((i) => {
							return i.name === this.name;
						});
					} else {
						return item.name === this.name;
					}
				})
				? "active"
				: "";
			} else {
				return el.name === this.name ? "active" : "";
			}
		},
		handleScroll(){
			this.scrollTop = window.scrollY;
			if(window.scrollY > document.getElementById('app').offsetHeight - 1400){
				this.tabsShow = false
			}else{
				this.tabsShow = true
			}
		},
		// 返回顶部
		toTop() {
			let _self = this;
			document.documentElement.scrollTop = 0;
			this.show = false;
		},
		// 跳转首页
		toHome() {
			this.$router.push("/");
			this.toTop();
		},
		a(){
			this.childrenList = [];
		},
		// 获取数据
		getData() {
			let _self = this;
			// getServer().then((res) => {
			// 	store.state.serverUrl = res.data;
			// 	console.log(this.$store.state);
				
			// });
			getIndexAd().then((res) => {
				_self.indexData = res.data.web_info;
			});
			getCreditList().then((res) => {
				_self.serverList = res.data;
			});
		},
		// 搜索
		search() {
			console.log(this.value);
			this.$router.push(`/search?value=${this.value}`);
		},
		changeRoute(item) {
			console.log(item);
			if (item.children) {
				this.propsName = item.name;
				this.childrenName = item.children[0].name;
				this.$router.push(item.children[0].path);
			} else {
				this.$router.push('/');
				this.toTop();
			}
		},
		changeLang(){
			if (this.$store.state.lang  == 'china') {
				this.$store.state.lang = 'en'
			} else{
				this.$store.state.lang = 'china'
			}
			this.show = false;
			this.toTop();
		},
		childrenSelect(i){
			this.childrenName = i.name;
		},
		tabsBtnMousemove(im){
			let _self = this;
			// _self.hover = true;
			this.childrenList = [];
			if (im.children) {
				_self.childrenObj = im.meta;
				if (im.name == 'Service') {
					// this.serverList.forEach((item,index)=>{
					// 	this.childrenList.push({
					// 		path:'/Service/Main',
					// 		meta:{
					// 			china:item.type_name,
					// 			en:'Service'
					// 		}
					// 	})
					// })
				} else{
					this.childrenList = im.children;
				}
			}
		},
		popShow(){
			let _self = this;
			_self.show = !_self.show;
			
		}
	},
	mounted() {
		window.addEventListener('scroll',this.handleScroll);
		this.innerHeight = window.innerHeight;
	},
};
</script>

<style lang="scss">
a {
  text-decoration: none;
  color: #ffffff;
}

a:link {
  color: #fff;
}

* {
	padding: 0;
	margin: 0;
	font-size: 14px;
	box-sizing: border-box;
	font-family: Microsoft YaHei, sans-serif !important;
	@media screen and (min-width: 768px) and (max-width: 1200px) {
		font-size: 12px !important;
	}
	@media screen and (max-width: 768px) {
		
	}
}

html,
body {
	width: 100%;
	height: 100%;
	@media screen and (max-width: 768px) {
		font-size: 12px !important;
	}
}

#app {
	width: 100%;
	height: auto;
	.ls-flex{
		display: flex;
	}
	.ls-flex-col{
		display: flex;
		flex-direction: column;
	}
	.ls-flex-center{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.ls-flex-col-center{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.ls-al-center{
		align-items: center;
	}
	.ls-al-end{
		align-items: flex-end;
	}
	.ls-jc-center{
		justify-content: center;
	}
	.ls-jc-space-between{
		justify-content: space-between;
	}
	.ls-jc-space-evenly{
		justify-content: space-evenly;
	}
	.ls-flex-flex{
		flex: 1;
	}
	.ls-flex-rw{
		flex-flow: row wrap;
	}
	.f-fff{
		color: #fff !important;
	}
	.f-000{
		color: #000 !important;
	}
	.f-333{
		color: #333 !important;
	}
	.f-666{
		color: #666 !important;
	}
	.f-999{
		color: #999 !important;
	}
	.f-EB612A{
		color: #EB612A;
	}
	.f-C9A063{
		color: #C9A063 !important;
	}
	.f-131313{
		color: #131313 !important;
	}
	.bg-c-fff{
		background-color: #fff;
	}
	.bg-c-f8f8f8{
		background-color: #f8f8f8;
	}
	.bg-c-EFEFF1{
		background-color: #EFEFF1;
	}
	.bg-c-C9A063{
		background-color: #C9A063;
	}
	.bg-c-EB612A{
		background-color: #EB612A;
	}
	.bg-c-d5d5d5{
		background-color: #d5d5d5;
	}
	.bg-c-ECECEC{
		background-color: #ECECEC;
	}
	.bg-c-04447C{
		background-color: #04447C;
	}
	.bg-c-E8E8E8{
		background-color: #E8E8E8;
	}
	.f-bold{
		font-weight: bold;
	}
	.ta-center{
		text-align: center;
	}
	/* // 字体 */
	.fs-14{
		font-size: 0.875rem !important;
	}
	.fs-16{
		font-size: 1rem !important;
	}
	.fs-18{
		font-size: 1.125rem !important;
	}
	.fs-20{
		font-size: 1.25rem !important;
	}
	.fs-22{
		font-size: 1.375rem !important;
	}
	.fs-24{
		font-size: 1.5rem !important;
	}
	.fs-28{
		font-size: 1.75rem !important;
	}
	.fs-26{
		font-size: 1.625rem !important;
	}
	.fs-30{
		font-size: 1.875rem !important;
	}
	.fs-32{
		font-size: 2rem !important;
	}
	.fs-34{
		font-size: 2.125rem !important;
	}
	.fs-36{
		font-size: 2.25rem !important;
	}
	.fs-38{
		font-size: 2.375rem !important;
	}
	.fs-40{
		font-size: 2.5rem !important;
	}
	.fs-42{
		font-size: 2.625rem !important;
	}
	.fs-46{
		font-size: 2.875rem !important;
	}
	.fs-60{
		font-size: 3.75rem !important;
	}
	@media screen and (max-width: 768px) {
		
	}
	.l-h-24{
		line-height: 1.5rem;
	}
	.l-h-28{
		line-height: 1.75rem;
	}
	.l-h-30{
		line-height: 1.875rem;
	}
	.l-h-32{
		line-height: 2rem;
	}
	.l-h-34{
		line-height: 2.125rem;
	}
	.l-h-36{
		line-height: 2.25rem;
	}
	.l-h-38{
		line-height: 2.375rem;
	}
	.l-h-40{
		line-height: 2.5rem;
	}
	.f-family{
		font-family: Microsoft YaHei;
		font-style: italic;
	}
	/* // margin上下 */
	.m-tb-10{
		margin: 0.625rem 0;
	}
	.m-tb-20{
		margin: 1.25rem 0;
	}
	.m-tb-30{
		margin: 1.875rem 0;
	}
	.m-tb-40{
		margin: 2.5rem 0;
	}
	.m-tb-50{
		margin: 3.125rem 0;
	}
	/* // margin左右 */
	.m-lr-10{
		margin: 0 0.625rem;
	}
	.m-lr-20{
		margin: 0 1.25rem;
	}
	.m-lr-30{
		margin: 0 1.875rem;
	}
	.m-lr-40{
		margin: 0 2.5rem;
	}
	.m-lr-50{
		margin: 0 3.125rem;
	}
	/* // padding上下 */
	.p-tb-10{
		padding: 0.625rem 0;
	}
	.p-tb-15{
		padding: 0.9375rem 0;
	}
	.p-tb-20{
		padding: 1.25rem 0;
	}
	.p-tb-30{
		padding: 1.875rem 0;
	}
	.p-tb-40{
		padding: 2.5rem 0;
	}
	.p-tb-50{
		padding: 3.125rem 0;
	}
	/* // padding左右 */
	.p-lr-10{
		padding: 0 0.625rem;
	}
	.p-lr-20{
		padding: 0 1.25rem;
	}
	.p-lr-30{
		padding: 0 1.875rem;
	}
	.p-lr-40{
		padding: 0 2.5rem;
	}
	.p-lr-50{
		padding: 0 3.125rem;
	}
	/* // margin-left */
	.m-l-10{
		margin-left: 0.625rem;
	}
	.m-l-15{
		margin-left: 0.9375rem;
	}
	.m-l-20{
		margin-left: 1.25rem;
	}
	.m-l-30{
		margin-left: 1.875rem;
	}
	.m-l-40{
		margin-left: 2.5rem;
	}
	.m-l-50{
		margin-left: 3.125rem;
	}
	.m-l-80{
		margin-left: 5rem;
	}
	/* // margin-right */
	.m-r-10{
		margin-right: 0.625rem;
	}
	.m-r-15{
		margin-right: 0.9375rem;
	}
	.m-r-20{
		margin-right: 1.25rem;
	}
	.m-r-25{
		margin-right: 1.5625rem;
	}
	.m-r-30{
		margin-right: 1.875rem;
	}
	.m-r-40{
		margin-right: 2.5rem;
	}
	.m-r-50{
		margin-right: 3.125rem;
	}
	/* // margin-top */
	.m-t-10{
		margin-top: 0.625rem;
	}
	.m-t-15{
		margin-top: 0.9375rem;
	}
	.m-t-20{
		margin-top: 1.25rem;
	}
	.m-t-25{
		margin-top: 1.5625rem;
	}
	.m-t-30{
		margin-top: 1.875rem;
	}
	.m-t-35{
		margin-top: 35px;
	}
	.m-t-40{
		margin-top: 2.5rem;
	}
	.m-t-50{
		margin-top: 3.125rem;
	}
	/* // margin-top */
	.m-b-10{
		margin-bottom: 0.625rem;
	}
	.m-b-15{
		margin-bottom: 0.9375rem;
	}
	.m-b-20{
		margin-bottom: 1.25rem;
	}
	.m-b-25{
		margin-bottom: 1.5625rem;
	}
	.m-b-30{
		margin-bottom: 1.875rem;
	}
	.m-b-40{
		margin-bottom: 2.5rem;
	}
	.m-b-50{
		margin-bottom: 3.125rem;
	}
	/* // padding-left */
	.p-l-10{
		padding-left: 0.625rem;
	}
	.p-l-20{
		padding-left: 1.25rem;
	}
	.p-l-30{
		padding-left: 1.875rem;
	}
	.p-l-40{
		padding-left: 2.5rem;
	}
	.p-l-60{
		padding-left: 3.75rem;
	}
	/* // padding-right */
	.p-r-10{
		padding-right: 0.625rem;
	}
	.p-r-20{
		padding-right: 1.25rem;
	}
	.p-r-30{
		padding-right: 1.875rem;
	}
	.p-r-40{
		padding-right: 2.5rem;
	}
	/* // padding-top */
	.p-t-10{
		padding-top: 0.625rem;
	}
	.p-t-20{
		padding-top: 1.25rem;
	}
	.p-t-30{
		padding-top: 1.875rem;
	}
	.p-t-40{
		padding-top: 2.5rem;
	}
	/* // padding-bottom */
	.p-b-10{
		padding-bottom: 0.625rem;
	}
	.p-b-20{
		padding-bottom: 1.25rem;
	}
	.p-b-30{
		padding-bottom: 1.875rem;
	}
	.p-b-40{
		padding-bottom: 2.5rem;
	}
	/* // padding */
	.p-10{
		padding: 0.625rem;
	}
	.p-15{
		padding: 0.9375rem;
	}
	.p-20{
		padding: 1.25rem;
	}
	.p-30{
		padding: 1.875rem;
	}
	.p-40{
		padding: 2.5rem;
	}
	.b-r-10{
		border-radius: 0.625rem;
	}
	.b-r-20{
		border-radius: 1.25rem;
	}
	.b-r-50{
		border-radius: 50%;
	}
	.parting-line-e5e5e5{
		height: 1px;
		background-color: #e5e5e5;
	}
	.parting-line-f8f8f8{
		height: 1px;
		background-color: #f8f8f8;
	}
	.text-ellipsis-1{
		overflow: hidden;
		-webkit-line-clamp: 1;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}
	.text-ellipsis-2{
		overflow: hidden;
		-webkit-line-clamp: 2;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}
	.text-ellipsis-3{
		overflow: hidden;
		-webkit-line-clamp: 3;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}
	.ov-hidden{
		overflow: hidden;
	}
	.view_img_bg{
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	.max-width{
		@media screen and (min-width: 1200px) {
			width: 1200px;
		}
		// @media screen and (max-width: 1198px) and (min-width:900px) {
		// 	width: 900px;
		// }
		@media screen and (max-width: 768px) {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 15px;
		}
	}
	.w-100{
		width: 100%;
	}
	.w-max-content{
		width: max-content;
	}
	.h-100{
		height: 100%;
	}
	.h-0{
		height: 0;
	}
	.cursor-pointer{
		cursor: pointer;
	}
}

main {
	// padding-top: 6.5rem;
	height: auto;
	width: 100%;
	background: #fff;
	

	@media screen and (max-width: 768px) {
		width: 100% !important;
		background: #f3f3f3;
		padding-bottom: 0 !important;
		// padding-top: 50px !important;
	}
	.view_title{
		position: relative;
		width: 100%;
		.text{
			min-width: 200px;
			width: max-content;
			height: 55px;
			text-align: center;
			background-repeat: no-repeat;
			background-size: auto;
			background-position: bottom;
			background-image: url('./assets/title_bg.png');
			@media screen and (max-width: 768px) {
				min-width: 120px;
				height: 35px;
				background-size: contain;
			}
		}
		.text_two{
			width: max-content;
			height: 40px;
			text-align: center;
			background-repeat: no-repeat;
			background-size: 66%;
			background-position: bottom;
			background-image: url('./assets/title_bg.png');
		}
		.text_one{
			width: max-content;
			height: 40px;
			text-align: center;
			background-repeat: no-repeat;
			background-size: 53%;
			background-position: bottom;
			background-image: url('./assets/title_bg.png');
		}
		.more{
			width: 150px;
			height: 50px;
			border: 1px solid #C9A063;
			position: absolute;
			top: 0;
			right: 0;
			@media screen and (max-width: 768px) {
				width: 80px;
				height: 30px;
			}
		}
	}
}
.BreadcrumbVue{
	@media screen and (max-width: 768px) {
		display: none !important;
	}
}
.home {
	width: 100%;
	height: auto;

	@media screen and (max-width: 768px) {
		background: rgb(243, 243, 243) !important;
	}
}

.logo1 {
	display: none;

	@media screen and (max-width: 768px) {
		display: block;
		height: 3.75rem !important;
	}
}

header {
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 999;
	@media screen and (max-width: 768px) {
		// position: relative;
		.logo {
			position: fixed !important;
		}
	}
}
.tabs:hover{
	background-color: #fff;
}
.tabs:hover .tabs_box{
	display: block;
	background-color: #fff;
}
.tabs:hover a{
	color: #000 !important;
}
.tabs {
	width: 100%;
	// height: 6.5rem;
	@media screen and (max-width: 768px) {
		height: 50px !important;
		background-color: transparent ;
	}
	// box-shadow: #1c1e1e 0px -5px 15px;
	.logo{
		padding: 50px 0;
		@media screen and (max-width: 768px) {
			padding: 0 20px !important;
			width: 100%;
		}
		.logo_line{
			width: 1px;
			background: #BFA180;
			@media screen and (max-width: 768px) {
				height: 24px;
			}
		}
		.logo_img_1{
			width: 282px;
			height: 48px;
			@media screen and (max-width: 768px) {
				width: 141px;
				height: 24px;
			}
		}
		.logo_img_2{
			width: 73px;
			height: 50px;
			@media screen and (max-width: 768px) {
				width: 36px;
				height: 25px;
			}
		}
	}
	.window_tabs_btn{
		@media screen and (max-width: 768px) {
			display: none !important;
		}
	}
	.phone_tabs_btn{
		display: none;
	}
	.phone_tabs_btn{
		@media screen and (max-width: 768px) {
			display: block !important;
		}
		@media screen and (min-width: 768px) {
			display: none !important;
		}
	}
	.tabs_content{
		width: 1200px;
		@media screen and (max-width: 768px) {
			padding: 0 1.875rem;
		}
	}
	.tabs_box{
		width: 100%;
		min-height: 280px;
		position: relative;
		display: none;
		z-index: 999;
		.tabs_box_box{
			width: 1200px;
			height: 100%;
			background-color: #fff;
			border-top: 1px solid #f7f7f7;
		}
		.window_tabs_btn_left{
			padding-top: 40px;
		}
		.children{
			position: relative;
			top: 40px;
			.children_item{
				position: absolute;
				top: 0;
				left: 0;
				.children_item_im{
					width: 45%;
					margin-left: 5%;
					margin-bottom: 40px;
					a{
						height: 65px;
					}
				}
				.img{
					width: 18px;
					height: 18px;
					background-repeat: no-repeat;
					background-size: 100%;
				}
				.children_item_im:hover .img{
					background-image: url('./assets/header_more.png');
				}
				.children_item_line{
					height: 2px;
					background: #F2F2F2;
					position: relative;
					.children_item_line_div{
						width: 20%;
						position: absolute;
						top: 0;
						left: 0;
						background: #3546A0;
						transition: all 0.5s 0s;
					}
				}
				.children_item_im:hover .children_item_line_div{
					width:100%;
				}
				.span_children_item_1{
					color: #999;
				}
				.span_children_item_2{
					color: rgba(4, 68, 124, 0.6);
				}
				.children_item_im:hover .span_children_item_1{
					color: #333;
				}
				.children_item_im:hover .span_children_item_2{
					color: rgba(4, 68, 124, 1);
				}
			}
		}
		
	}
	.tabs_btn_logo{
		@media screen and (max-width: 768px) {
			width: 70%;
		}
		height: 100%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		overflow: hidden;
		img{
			width: 100%;
			height: auto;
		}
	}
	.tabs_btn {
		height: 100%;
		margin: 0 auto;
		display: flex;
		align-items: center;
		overflow: hidden;
		img{
			width: 100%;
			height: fit-content;
		}
		ul {
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
			height: 100%;

			.active {

				a {
					color:#EB612A !important;
				}
			}
			.hoverLi:hover a{
				color:#EB612A !important;
			}
			.hoverLi:hover .tabs{
				background-color: #3546A0;
			}
			li {
				list-style: none;
				// width: 7.5rem;
				height: 100%;
				font-size: 1.25rem;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				line-height: 1.5625rem;
				display: flex;
				align-items: center;
				justify-content: space-around;

				a {
					// width: 7.5rem;
					// height: 1.5625rem;
					border-radius: 1.5625rem;
					font-size: 1.25rem;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #fff;
					// line-height: 1.5625rem;
					display: block;
					padding: 0.375rem 0;
					font-weight: bold;
					border-bottom: transparent 2px solid;
					text-align: center;
				}
				.lang{
					width: 7.5rem;
					// height: 1.5625rem;
					border-radius: 1.5625rem;
					font-size: 1.25rem;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #333;
					// line-height: 1.5625rem;
					padding: 0.375rem 0;
					font-weight: bold;
					border-bottom: transparent 2px solid;
					text-align: center;
					cursor: pointer;
				}
			}
		}
	}
}

footer {
  // margin-top: 6.5625rem;
	.QRCode{
		margin-left: 1.875rem;
	}
	.parting-line-BFA180{
		width: 1px;
		height: 125px;
		background: #BFA180;
	}
	@media screen and (max-width: 768px) {

		.QRCode{
			font-size: 0.75rem;
			margin-left: 0 !important;
			transform: scale(0.9);
		}
		.QRCode_box{
			display: flex;
			flex-flow: row wrap;
			div{
				flex: 1;
			}
		}
		.QRCode_img{
			justify-content: center !important;
			.parting-line-BFA180{
				width: 1px;
				height: 85px;
				background: #BFA180;
			}
		}

		.bottom {
			height: auto !important;
			font-size: 0.875rem !important;
			text-align: center !important;
		}
	}
	@media screen and (min-width: 1200px) {
		
	}
	.correlation {
		width: 100%;
		// height: 49.5rem;
		background-image: url('./assets/foot_bg.png');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		padding-bottom: 3.125rem;
		display: flex;
		justify-content: center;
		@media screen and (max-width: 768px) {
			flex-direction: column;
		}
		.corr-content_phone{
			display: none;
			@media screen and (max-width: 768px) {
				display: block;
			}
			padding: 15px 15px 0 15px;
			ul{
				list-style: none;
				li{
					width: 70px;
					text-align: center;
					margin-bottom: 15px;
					color: #fff;
				}
			}
		}
		.corr-content {
			// width: calc(100% - 28.375rem);
			height: auto;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			// margin: 0 auto;
	
			@media screen and (max-width: 768px) {
				display: none !important;
			}
	
			.index {
				display: none;
			}
	
			ul {
				width: auto;
				list-style: none;
				color: #ffffff;
				margin-top: 2.25rem;
				flex-shrink: 0;
	
				h1 {
				  font-size: 1.25rem;
				  font-weight: bold;
				  line-height: 1.75rem;
				  cursor: pointer;
				}
	
				span {
				  display: block;
				  background: #fff;
				  width: 2.25rem;
				  height: 0.125rem;
				  margin-top: 0.1875rem;
				}
	
				li {
				  font-size: 1rem;
				  font-weight: 400;
				  color: #d1d5d6;
				  line-height: 2.1875rem;
	
				  a {
					color: #d1d5d6;
				  }
				}
			}
		}
	}



	.bottom {
		width: 100%;
		height: 4.6875rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #EB6935;

		@media screen and (max-width: 768px) {
			padding: 10px 0 30px 0;
			flex-direction: column;
			p {
				font-size: 0.75rem !important;
				a{
					font-size: 0.75rem !important;
				}
			}
		}

		p {
			width: auto;
			height: auto;
			font-size: 1.125rem;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #ffffff;
			line-height: 1.5625rem;
		}
		a {
			font-size: 1.125rem;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #ffffff;
			line-height: 1.5625rem;
		}
	}
}

.home_popup {
	width: 100vw !important;
	height: 100vh !important;
	padding: 15px;
	position: fixed;
	z-index: 998;
	top: 50px;
	left: 0;
	transition: all 0.5s 0s;
	background-color: #fff;
	@media screen and (min-width: 768px) {
		display: none !important;
	}
	.correlation {
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		
		.corr-content {
			.img{
				width: 18px;
				height: 18px;
				background-repeat: no-repeat;
				background-size: 100%;
				background-image: url('./assets/header_more.png');
			}
		}
	}
}
.top{
	width: 80px;
	height: 80px;
	background-color: rgba(0, 0, 0, 0.6);
	border-radius: 10px;
	position: fixed;
	bottom: 150px;
	right: 0;
	@media screen and (max-width: 768px) {
		width: 50px;
		height: 50px;
		bottom: 70px;
	}
	.span_1{
		transform: rotateZ(90deg)
	}
	span{
		font-size: 24px;
		@media screen and (max-width: 768px) {
			font-size: 16px;
		}
	}
}
// 进度条颜色
// #nprogress .bar {
//   background: blue !important; //这里可以随便写颜色
// }
</style>
