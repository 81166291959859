import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map'
import Scroll from 'vue-seamless-scroll'
import Dragscroll from 'vue-dragscroll'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import { Swipe, SwipeItem, Tab, Tabs } from 'vant';
import cetihuan from './utils/cetihuan.js'
import 'swiper/dist/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
Vue.use(SwipeItem);
Vue.use(Tab);
Vue.use(Tabs);

Vue.prototype.$video = Video;
Vue.prototype.$cetihuan = cetihuan;

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(BaiduMap, {
  ak: "2Zr4CDKYqTkG426wZeyxhETrPG83NDih"
})
Vue.use(Scroll);
Vue.use(Dragscroll);




// 正式环境清除所有console.log
// if (window) {
//   window.console.log = function () { };
// }



new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
  	document.dispatchEvent(new Event('render-event'))
  	// document.dispatchEvent(new Event('custom-render-trigger'))
  	// document.dispatchEvent(new Event('prerender'))
  }
}).$mount('#app')
