let data = {
	more:{
		china:'查看更多',
		en:'more'
	},
	nian:{
		china:'年',
		en:'year'
	},
	company:{
		china:'公司成立于',
		en:'the company was established in'
	},
	subsidiary:{
		china:'旗下子公司',
		en:'subsidiary'
	},
	staff:{
		china:'在职员工',
		en:'staff on active duty'
	},
	person:{
		china:'人',
		en:'person'
	},
	individual:{
		china:'个',
		en:'individual'
	},
	series:{
		china:'更多系列',
		en:'More series'
	},
	QRCode:{
		china:'扫一扫关注我们',
		en:'QRCode'
	},
	copyright:{
		china:'版权',
		en:'copyright'
	},
	beian:{
		china:'备案号',
		en:'Record n varchar'
	},
	jishu:{
		china:'技术支持',
		en:'technical support'
	},
	khrs:{
		china:'科辉荣盛',
		en:'KeHuiRongSheng'
	},
	gsxw:{
		china:'公司新闻',
		en:'Company'
	},
	hydt:{
		china:'行业动态',
		en:'Industry'
	},
	xxgk:{
		china:'信息公开',
		en:'Information disclosure'
	},
	zlxz:{
		china:'资料下载',
		en:'Download'
	},
	zzjz:{
		china:'住宅建筑',
		en:'Housing Construction'
	},
	ggjz:{
		china:'公共建筑',
		en:'Public Construction'
	},
	gyjz:{
		china:'工业建筑',
		en:'Industrial Construction'
	},
	syjz:{
		china:'商业建筑',
		en:'Business Construction'
	},
	gwjz:{
		china:'国外建筑',
		en:'Foreign Construction'
	},
	zlxz:{
		china:'资料下载',
		en:'Download'
	},
	fanhui:{
		china:'返回列表',
		en:'Back List'
	},
	dcwz:{
		china:'当前位置',
		en:'Current Position'
	},
	home:{
		china:'首页',
		en:'Home'
	},
	kzcpzx:{
		china:'康庄产品中心',
		en:'Kangzhuang product center'
	},
	weixin:{
		china:'关注微信公众号',
		en:'Follow WeChat public accounts'
	},
	kefu:{
		china:'联系公司客服',
		en:'Contact customer service'
	},
	mailbox:{
		china:'邮箱',
		en:'mailbox'
	},
	telephone:{
		china:'电话',
		en:'telephone'
	},
	code:{
		china:'邮编',
		en:'postal code'
	},
	address:{
		china:'地址',
		en:'address'
	},
	wangzhi:{
		china:'网址',
		en:'URL'
	},
	connectUsOne:{
		china:'在线提交您的需求',
		en:'Submit your requirements online'
	},
	connectUsTwo:{
		china:'我们会在24小时内回复您的留言，感谢您的支持与信任。',
		en:'We will reply to your message within 24 hours, thank you for your support and trust.'
	},
	fwrx:{
		china:'全国服务热线',
		en:'National service hotline'
	},
	ljxq:{
		china:'了解详情',
		en:'find out details'
	},
	qgrx:{
		china:'全国热线',
		en:'National hotline'
	},
	zxzx:{
		china:'在线咨询',
		en:'online consultant'
	},
	moreCase:{
		china:'更多工程案例',
		en:'More engineering cases'
	},
	subsidiary:{
		china:'旗下公司',
		en:'Subsidiary'
	},
	lxwm:{
		china:'联系我们',
		en:'Contact Us'
	},
	tijiao:{
		china:'提交',
		en:'Submit'
	},
	textarea:{
		china:'请输入您的留言内容………',
		en:'Please enter your message………'
	},
	inputName:{
		china:'请输入您的姓名………',
		en:'Please enter your name………'
	},
	name:{
		china:'姓名',
		en:'Name'
	},
	inputPhone:{
		china:'请输入您的电话………',
		en:'Please enter your phone number………'
	},
	phone:{
		china:'电话',
		en:'Phone'
	},
	inputAdderss:{
		china:'请选择您所在地区………',
		en:'Please select your region………'
	},
	region:{
		china:'所在地区',
		en:'Region'
	},
	inputEmail:{
		china:'请输入您的邮箱………',
		en:'Please enter your email address………'
	},
	email:{
		china:'邮箱',
		en:'Email'
	},
	sousuo:{
		china:'搜索',
		en:'Search'
	}
}
export default data;