import requestServer from '@/utils/requestServer';
import request from '@/utils/request';
//网站配置数据
export function getServer() {
    return requestServer({
        url: 'index/geturl',
        method: 'get',
        params:{
			title:'尚宁医疗'
		}
    });
}
//首页数据
export function getIndexAd(params) {
    return request({
        url: 'index/index',
        method: 'get',
        params: params || ''
    });
}
//尚宁服务
//类型列表
export function getCreditList() {
    return request({
        url: 'credit/type_list',
        method: 'get'
    });
}
//服务详情
export function getCreditDetail(type) {
    return request({
        url: 'credit/detail',
        method: 'get',
		params: {
			type:type
		}
    });
}
// 走进尚宁
export function getCompanyData(type) {
    return request({
        url: 'company/index',
        method: 'get',
		params: {
			type:type
		}
    });
}
// 尚宁资讯
// 资讯列表
export function getMeetnoticeList(params) {
    return request({
        url: 'meetnotice/index',
        method: 'get',
		params:params
    });
}
export function getMeetnoticeDetail(params) {
    return request({
        url: 'meetnotice/detail',
        method: 'get',
		params:params
    });
}
// 尚宁文化
// 党工团建设列表
export function getCultureList(params) {
    return request({
        url: 'culture/index',
        method: 'get',
		params:params
    });
}
// 党工团建设详情
export function getCultureDetail(params) {
    return request({
        url: 'culture/detail',
        method: 'get',
		params:params
    });
}
// 尚宁家园列表
export function getPolicyList(params) {
    return request({
        url: 'culture/policy_server_list',
        method: 'get',
		params:params
    });
}
// 尚宁家园详情
export function getPolicyDetail(params) {
    return request({
        url: 'culture/policy_server_detail',
        method: 'get',
		params:params
    });
}
// 尚宁刊物列表
export function getFinanceList(params) {
    return request({
        url: 'culture/finance_server_list',
        method: 'get',
		params:params
    });
}
// 尚宁刊物详情
export function getFinanceDetail(params) {
    return request({
        url: 'culture/finance_server_detail',
        method: 'get',
		params:params
    });
}
// 十三华章
// 列表
export function getEventnotifityList(params) {
    return request({
        url: 'eventnotifity/index',
        method: 'get',
		params:params
    });
}
// 详情
export function getEventnotifityDetail(params) {
    return request({
        url: 'eventnotifity/detail',
        method: 'get',
		params:params
    });
}
// 尚宁园区
// 尚宁园区列表及园区介绍
export function getParkList(params) {
    return request({
        url: 'culture/park_list',
        method: 'get',
		params:params
    });
}
// 效果图展示列表
export function getCelebrateList(params) {
    return request({
        url: 'culture/celebrate',
        method: 'get',
		params:params
    });
}
// 园区动态详情
export function getParkDetail(params) {
    return request({
        url: 'culture/park_detail',
        method: 'get',
		params:params
    });
}
// 招聘
// 招聘列表
export function getRecruitList(params) {
    return request({
        url: 'recruit/index',
        method: 'get',
		params:params
    });
}
// 招聘详情
export function getRecruitDetail(params) {
    return request({
        url: 'recruit/detail',
        method: 'get',
		params:params
    });
}
// 职位投递
export function getRecruitAdd(params) {
    return request({
        url: 'recruit/add',
        method: 'post',
		params:params
    });
}
// 尚宁担当
// 尚宁担当列表
export function getUndertakeList(params) {
    return request({
        url: 'undertake/index',
        method: 'get',
		params:params
    });
}
// 校企合作列表
export function getSchoolList(params) {
    return request({
        url: 'undertake/school_cooperate_list',
        method: 'get',
		params:params
    });
}
// 尚宁担当详情
export function getUndertakeDetail(params) {
    return request({
        url: 'undertake/detail',
        method: 'get',
		params:params
    });
}