const routes = [
    //首页
    {
        path: '/',
        name: 'HomePage',
        meta: { china:'首页',en:'Home' },
        component: () => import('@/views/HomePage.vue'),
    },
    //走进尚宁
    {
        path: '/GoHome/Main',
        name: 'GoHome',
        meta: { china:'走进尚宁',en:'Into Shining' },
        component: () => import('../views/GoHome/Index.vue'),
		children: [
			{
				path: '/GoHome/Main',
				name: 'CompanyProfile',
				meta: { china:'公司简介',en:'Shining Profile' },
				component: () => import('../views/GoHome/Main.vue'),
			},
			// {
			// 	path: '/GoHome/Main',
			// 	name: 'MissionVision',
			// 	meta: { china:'使命愿景',en:'Mission Vision' },
			// 	component: () => import('../views/GoHome/Main.vue'),
			// },
			{
				path: '/GoHome/Main',
				name: 'Shining Industry',
				meta: { china:'尚宁产业',en:'Shining Industry' },
				component: () => import('../views/GoHome/Main.vue'),
			},
			{
				path: '/GoHome/Main',
				name: 'Development History',
				meta: { china:'企业历程',en:'Shining History' },
				component: () => import('../views/GoHome/Main.vue'),
			},
			{
				path: '/GoHome/Main',
				name: 'Shining Honor',
				meta: { china:'尚宁荣誉',en:'Shining Honor' },
				component: () => import('../views/GoHome/Main.vue'),
			},
		]
    },
    //尚宁资讯
    {
        path: '/Information/Main',
        name: 'Information',
        meta: { china:'尚宁资讯',en:'Shining News' },
        component: () => import('../views/Information/Index.vue'),
		children: [
			{
				path: '/Information/Main',
				name: 'CompanyNews',
				meta: { china:'公司新闻',en:'Shining News' },
				component: () => import('../views/Information/Main.vue'),
			},
			{
				path: '/Information/Detail',
				name: 'InformationDetail',
				meta: { china:'资讯详情',en:'Detail' },
				component: () => import('../views/Information/Detail.vue'),
			}
		]
    },
    //尚宁文化
    {
        path: "/Culture/Main",
        name: "Culture",
        meta: { china:'尚宁文化',en:'Shining Culture' },
        component: () => import('../views/Culture/Index.vue'),
		children: [
			{
				path: '/Culture/Main',
				name: 'CultureMain',
				meta: { china:'党工团建设',en:'Party Building' },
				component: () => import('../views/Culture/Main.vue'),
			},
			{
				path: '/Culture/Main',
				name: 'CultureMain',
				meta: { china:'尚宁家园',en:'Shining Home' },
				component: () => import('../views/Culture/Main.vue'),
			},
			{
				path: '/Culture/Main',
				name: 'CultureMain',
				meta: { china:'尚宁刊物',en:'Shining Publications' },
				component: () => import('../views/Culture/Main.vue'),
			},
			{
				path: '/Culture/Detail',
				name: 'CultureDetail',
				meta: { china:'资讯详情',en:'Detail' },
				component: () => import('../views/Culture/Detail.vue'),
			}
		]
    },
    //尚宁担当
    {
        path: '/TakeOn/Main',
        name: "TakeOn",
        meta: { china:'尚宁担当',en:'Shining Take On' },
        component: () => import('../views/TakeOn/Index.vue'),
		children: [
			{
				path: '/TakeOn/Main',
				name: 'TakeOnMain',
				meta: { china:'校企合作',en:'Shining Cooperation' },
				component: () => import('../views/TakeOn/Main.vue'),
			},
			{
				path: '/TakeOn/Main',
				name: 'TakeOnMain',
				meta: { china:'社区指导',en:'Community Guidance' },
				component: () => import('../views/TakeOn/Main.vue'),
			},
			{
				path: '/TakeOn/Main',
				name: 'TakeOnMain',
				meta: { china:'公益活动及社会影响',en:'Public Welfare' },
				component: () => import('../views/TakeOn/Main.vue'),
			},
			{
				path: '/TakeOn/Detail',
				name: 'TakeOnDetail',
				meta: { china:'详情',en:'Detail' },
				component: () => import('../views/TakeOn/Detail.vue'),
			}
		]
    },
    //三十华章
    {
        path: '/HuaZhang/Main',
        name: "HuaZhang",
        meta: { china:'三十华章',en:'Shining Thirty' },
        component: () => import('../views/HuaZhang/Index.vue'),
		children: [
			{
				path: '/HuaZhang/Main',
				name: 'HuaZhangMain',
				meta: { china:'活动筹备及通知',en:'Shining Activities' },
				component: () => import('../views/HuaZhang/Main.vue'),
			},
			{
				path: '/HuaZhang/Main',
				name: 'HuaZhangMain',
				meta: { china:'活动方案征集',en:'Activity' },
				component: () => import('../views/HuaZhang/Main.vue'),
			},
			{
				path: '/HuaZhang/Main',
				name: 'HuaZhangMain',
				meta: { china:'庆典表彰',en:'Celebration' },
				component: () => import('../views/HuaZhang/Main.vue'),
			},
			{
				path: '/HuaZhang/Detail',
				name: 'HuaZhangDetail',
				meta: { china:'资讯详情',en:'Detail' },
				component: () => import('../views/HuaZhang/Detail.vue'),
			}
		]
    },
    //尚宁园区
    {
        path: '/Park/Main',
        name: "Park",
        meta: { china:'尚宁园区',en:'Shining Park' },
        component: () => import('../views/Park/Index.vue'),
		children: [
			{
				path: '/Park/Main',
				name: 'ParkMain',
				meta: { china:'园区介绍',en:'Park' },
				component: () => import('../views/Park/Main.vue'),
			},
			{
				path: '/Park/Main',
				name: 'ParkMain',
				meta: { china:'效果图展示',en:'Shining Renderings' },
				component: () => import('../views/Park/Main.vue'),
			},
			{
				path: '/Park/Main',
				name: 'ParkMain',
				meta: { china:'园区动态',en:'Shining Trends' },
				component: () => import('../views/Park/Main.vue'),
			},
			// {
			// 	path: '/Park/Main',
			// 	name: 'ParkMain',
			// 	meta: { china:'VR展示',en:'Park' },
			// 	component: () => import('../views/Park/Main.vue'),
			// },
			{
				path: '/Park/Detail',
				name: 'ParkDetail',
				meta: { china:'资讯详情',en:'Detail' },
				component: () => import('../views/Park/Detail.vue'),
			}
		]
    },
    //尚宁服务
    {
        path: '/Service/Main',
        name: "Service",
        meta: { china:'尚宁服务',en:'Shining Services' },
        component: () => import('../views/Service/Index.vue'),
		children: [
			{
				path: '/Service/Main',
				name: 'ServiceMain',
				meta: { china:'尚宁服务',en:'Service' },
				component: () => import('../views/Service/Main.vue'),
			}
		]
    },
    //招聘信息
    {
        path: '/Recruit/Main',
        name: "Recruit",
        meta: { china:'招聘信息',en:'Recruit' },
        component: () => import('../views/Recruit/Index.vue'),
    	children: [
    		{
    			path: '/Recruit/Main',
    			name: 'Main',
    			meta: { china:'人才招聘',en:'Shining Recruitment' },
    			component: () => import('../views/Recruit/Main.vue'),
    		},
			{
				path: '/Recruit/Detail',
				name: 'RecruitDetail',
				meta: { china:'详情',en:'Detail' },
				component: () => import('../views/Recruit/Detail.vue'),
			}
    	]
    }
	
]

export default routes